import React, { Component } from 'react';
import { EpisodeList } from '../styles/podcastStyles';
import { TextCenter } from '../styles/globalStyles';
import PodcastItem from './podcastItem';

class PodcastList extends Component {
  render() {
    return (
      <div>
        <TextCenter>
          <h2>All episodes</h2>
        </TextCenter>
        <EpisodeList>
          {this.props.data.map(({ node }) => (
            <PodcastItem key={node.slug} {...node} />
          ))}
        </EpisodeList>
      </div>
    );
  }
}

export default PodcastList;
