import React, { Component } from 'react';
import {
  EpisodeItem,
  IconHolder,
  PodcastDuration,
  GuestImageHolder,
  PodcastDownloadHolder,
} from '../styles/podcastStyles';
import { Clock } from 'react-feather';
import getImageFromPath from '../images/podcastpage/podcastGuestImages';

class PodcastItem extends Component {
  render() {
    return (
      <EpisodeItem>
        <GuestImageHolder>
          {getImageFromPath(this.props.guestImageLink)}
        </GuestImageHolder>
        <div>
          <h3>{this.props.title}</h3>
          <h5>with {this.props.guestName}</h5>
        </div>
        <PodcastDuration>
          <IconHolder>
            <Clock />
          </IconHolder>
          <span>{this.props.duration}</span>
        </PodcastDuration>
        <PodcastDownloadHolder>
          <a
            href={this.props.downloadLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        </PodcastDownloadHolder>
      </EpisodeItem>
    );
  }
}

export default PodcastItem;
