import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container } from '../styles/globalStyles';
import Layout from '../components/layout';
import Seo from '../components/seo';

import PodcastHeader from '../partials/podcastHeader';
import PodcastList from '../partials/podcastList';
import PodcastFeatures from '../partials/podcastFeatures';

class PodcastPage extends Component {
  render() {
    return (
      <Layout>
        <Seo
          title="Podcast - IncrementalUX - Vinay Raghu - Software Developer"
          keywords={[`gatsby`, `application`, `react`]}
        />
        <PodcastHeader />
        <Container>
          <PodcastList data={this.props.data} />
        </Container>
        <PodcastFeatures />
      </Layout>
    );
  }
}

const PodcastsQuery = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulPodcastEpisode(sort: { fields: [order], order: ASC }) {
          edges {
            node {
              title
              date
              slug
              duration
              downloadLink
              guestName
              guestImageLink
              guestCompanyName
              guestCompanyLink
              guestDesignation
              guestTwitterLink
              guestWebsiteLink
              guestAbout {
                childMarkdownRemark {
                  html
                }
              }
              showNotes {
                childMarkdownRemark {
                  html
                }
              }
              episodeIntro {
                id
                childMarkdownRemark {
                  rawMarkdownBody
                  html
                }
              }
              downloadLink
              episodeTags
              rank
              order
            }
          }
        }
      }
    `}
    render={(data) => (
      <PodcastPage data={data.allContentfulPodcastEpisode.edges} />
    )}
  />
);

export default PodcastsQuery;
