import React, { Component } from 'react';
import { PodcastSection, PodcastHeroCardTitle } from '../styles/podcastStyles';
import {
  Container,
  CardBodySplit,
  BackgroundDiv,
} from '../styles/globalStyles';
import {
  PodcastHeroCardLeft,
  PodcastCardHeroRight,
} from '../styles/podcastStyles';
import HeroPodcast from '../images/podcastpage/hero-podcast';

import Are from '../images/podcastpage/guests/Are';
import Danielle from '../images/podcastpage/guests/Danielle';
import Jason from '../images/podcastpage/guests/Jason';
import Krystal from '../images/podcastpage/guests/Krystal';
import Melissa from '../images/podcastpage/guests/Melissa';
import Mg from '../images/podcastpage/guests/Mg';
import Nazmul from '../images/podcastpage/guests/Nazmul';
import Sarahdoody from '../images/podcastpage/guests/Sarahdoody';
import Stacey from '../images/podcastpage/guests/Stacey';
class PodcastHeader extends Component {
  render() {
    return (
      <PodcastSection>
        <BackgroundDiv>
          <HeroPodcast />
        </BackgroundDiv>
        <Container>
          <CardBodySplit>
            <PodcastHeroCardLeft>
              <PodcastHeroCardTitle>IncrementalUX</PodcastHeroCardTitle>
              <p>
                I am the host of a podcast mini-series called IncrementalUX.
                IncrementalUX is a user experience podcast focused on learning
                core UX principles.
              </p>
              <p>
                Each episode is an interview with a subject matter expert and
                dives deep into a specific topic. The key focus is on being able
                to implement the concepts in your day job right away.
              </p>
              <p>
                You can listen to the individual episodes below or{' '}
                <a
                  href="https://vinayraghuassets.s3.us-east-2.amazonaws.com/podcasts/all-episodes.zip"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  download all the epsiodes
                </a>
                .
              </p>
            </PodcastHeroCardLeft>
            <PodcastCardHeroRight>
              <Stacey />
              <Are />
              <Danielle />
              <Jason />
              <Krystal />
              <Melissa />
              <Mg />
              <Nazmul />
              <Sarahdoody />
            </PodcastCardHeroRight>
          </CardBodySplit>
        </Container>
      </PodcastSection>
    );
  }
}

export default PodcastHeader;
