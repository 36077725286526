import React from 'react';

import Are from './guests/Are';
import Danielle from './guests/Danielle';
import Jason from './guests/Jason';
import Krystal from './guests/Krystal';
import Melissa from './guests/Melissa';
import Mg from './guests/Mg';
import Nazmul from './guests/Nazmul';
import Sarahdoody from './guests/Sarahdoody';
import Stacey from './guests/Stacey';

const getImageFromPath = path => {
  switch (path) {
    case '/images/podcasts/jason-pamental.jpg':
      return <Jason />;
    case '/images/podcasts/are-halland.jpg':
      return <Are />;
    case '/images/podcasts/nazmul-idris.jpg':
      return <Nazmul />;
    case '/images/podcasts/stacey-messier.png':
      return <Stacey />;
    case '/images/podcasts/sarah-doody.jpg':
      return <Sarahdoody />;
    case '/images/podcasts/melissa-perri.jpg':
      return <Melissa />;
    case '/images/podcasts/mark-geyer.png':
      return <Mg />;
    case '/images/podcasts/krystal-higgins.png':
      return <Krystal />;
    case '/images/podcasts/danielle-smith.jpg':
      return <Danielle />;
    default:
      return null;
  }
};

export default getImageFromPath;
