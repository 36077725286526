import React from 'react';

import PodcastFeatureItem from './podcastFeatureItem';

import { Container, TextCenter } from '../styles/globalStyles';
import {
  UserCheck,
  Clock,
  HelpCircle,
  Layers,
  Zap,
  Server,
} from 'react-feather';
import {
  PodcastFeaturesContainer,
  PodcastFeaturesArticle,
} from '../styles/podcastStyles';

const podcastFeatures = () => {
  return (
    <PodcastFeaturesArticle>
      <Container>
        <TextCenter>
          <h2>Why listen?</h2>
        </TextCenter>
        <PodcastFeaturesContainer>
          <PodcastFeatureItem
            icon={<UserCheck />}
            title="Converse with Experts"
          >
            <p>
              Each guest was carefully chosen for their subject matter
              expertise.
            </p>
          </PodcastFeatureItem>
          <PodcastFeatureItem icon={<HelpCircle />} title="Interview format">
            <p>Follows a simple Q&A format, exploring a topic.</p>
          </PodcastFeatureItem>
          <PodcastFeatureItem icon={<Layers />} title="Nuanced">
            <p>Discusses the different nuances of using each technuque. </p>
          </PodcastFeatureItem>
          <PodcastFeatureItem
            icon={<Server />}
            title="Focused on implementation"
          >
            <p>Designed to be incorporated into your daily workflow.</p>
          </PodcastFeatureItem>
          <PodcastFeatureItem icon={<Zap />} title="Digestible">
            <p>A 9-part mini-series so you can consume in its entirety.</p>
          </PodcastFeatureItem>
          <PodcastFeatureItem icon={<Clock />} title="Detailed">
            <p>Focused hour-long episodes with no ads or interruptions.</p>
          </PodcastFeatureItem>
        </PodcastFeaturesContainer>
      </Container>
    </PodcastFeaturesArticle>
  );
};

export default podcastFeatures;
