import React from 'react';

const PodcastFeatureItem = ({ icon, title, children }) => {
  return (
    <section>
      {icon}
      <h4>{title}</h4>
      {children}
    </section>
  );
};

export default PodcastFeatureItem;
