import styled from '@emotion/styled';
import { Colors } from './globalStyles';

const PodcastSection = styled.section`
  display: grid;
  position: relative;
  padding: 30px;
  min-height: calc(100vh - 60px);
  align-items: center;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
`;

const EpisodeList = styled.ul`
  padding-top: 15px;
`;
const EpisodeItem = styled.li`
  display: grid;
  border: 1px solid;
  margin-bottom: 15px;
  padding: 15px;

  @media (min-width: 768px) {
    grid-template-columns: 20% 1fr 20% 20%;
    max-width: 666px;
    margin: 16px auto 16px auto;
    align-items: top;
    border: none;
  }
  div {
    padding-top: 8px;
    @media (min-width: 768px) {
      padding-top: 15px;
    }
  }

  img {
    max-width: 80px;
    max-height: 80px;
    border-radius: 50%;
  }

  h3,
  h5 {
    margin: 0;
  }
`;

const PodcastHeroCardLeft = styled.div`
  background: ${Colors.fullBlack};
  color: ${Colors.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  @media (min-width: 768px) {
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const PodcastCardHeroRight = styled.div`
  background: ${Colors.white};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  @media (min-width: 768px) {
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  img {
    border-radius: 50%;
  }
`;

const PodcastHeroCardTitle = styled.h3`
  margin-top: 0;
`;

const IconHolder = styled.aside`
  display: inline-block;
  margin-right: 8px;
  margin-top: 6px;
`;

const PodcastDuration = styled.div`
  display: flex;
  align-items: center;
`;

const GuestImageHolder = styled.div`
  max-height: 80px;
`;

const PodcastDownloadHolder = styled.div`
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const PodcastFeaturesContainer = styled.article`
  display: grid;
  @media (min-width: 768px) {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }

  h4 {
    margin-top: 0;
  }

  svg {
    width: 50px;
    height: 50px;
  }

  section {
    @media (min-width: 768px) {
      padding: 15px;
    }
  }
`;

const PodcastFeaturesArticle = styled.div`
  padding: 45px;
  background: ${Colors.grayLight};
`;

export {
  PodcastSection,
  EpisodeList,
  EpisodeItem,
  PodcastHeroCardLeft,
  PodcastHeroCardTitle,
  PodcastCardHeroRight,
  IconHolder,
  PodcastDuration,
  GuestImageHolder,
  PodcastDownloadHolder,
  PodcastFeaturesContainer,
  PodcastFeaturesArticle,
};
